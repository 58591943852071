import React from 'react';
import PropTypes from 'prop-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import './EmailForm.css';
// import Tick from '~/images/tick.jpg';

class EmailCaptureForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: `` };

    this._handleEmailChange = this._handleEmailChange.bind(this);
    this._validateEmail = this._validateEmail.bind(this);
    this._postEmailToMailchimp = this._postEmailToMailchimp.bind(this);
    this._handleFormSubmit = this._handleFormSubmit.bind(this);
  }

  // Update state each time user edits their email address
  _handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  _validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  }

  // Post to MC server & handle its response
  _postEmailToMailchimp(email, attributes) {
    addToMailchimp(email, attributes)
      .then((result) => {
        /*
         * Mailchimp always returns a 200 response
         * So we check the result for MC errors & failures
         */
        if (result.result === `success`) {
          // Email address succesfully subcribed to Mailchimp
          this.setState({
            status: `success`,
            msg: result.msg
          });
        } else {
          this.setState({
            status: `error`,
            msg: result.msg
          });
        }
      })
      .catch((err) => {
        // Network failures, timeouts, etc
        this.setState({
          status: `error`,
          msg: err
        });
      });
  }

  // eslint-disable-next-line consistent-return
  _handleFormSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!this._validateEmail(this.state.email)) {
      this.setState({
        status: `error`,
        msg: `Please enter a valid email address.`
      });
      return false;
    }

    this.setState(
      {
        status: `sending`,
        msg: null
      },
      // SetState callback (subscribe email to MC)
      // eslint-disable-next-line no-underscore-dangle
      this._postEmailToMailchimp(this.state.email, {
        pathname: document.location.pathname
        // https://github.com/benjaminhoffman/gatsby-plugin-mailchimp#mailchimp-list-fields
        // FNAME: 'Ben',
        // LNAME: 'Coder',
        // https://github.com/benjaminhoffman/gatsby-plugin-mailchimp#mailchimp-groups
        // 'group[21265][2]': '2',
      })
    );
  }

  // <p>Thanks for signing up.</p>
  // <p>Please check your inbox or spam folder for an <span className="emphasis">activation email</span>. Once you activate your email we will immediately spin up your trial instance.</p>
  render() {
    return (
      <div className="mailchimpForm">
        {this.state.status === `success` ? (
          <div>
            <h4 className="mailchimpForm-title">Almost done!</h4>
            {/*<h4 className="mailchimpForm-title">Never miss a post!</h4>*/}
            <p className="mailchimpForm-subTitle">
              Click the link in the email we just sent you to confirm your
              subscription.
            </p>
          </div>
        ) : (
          <div>
            <div className="leftCol">
              <h4 className="mailchimpForm-title">Join the Newsletter</h4>
              {/*<h4 className="mailchimpForm-title">Never miss a post!</h4>*/}
              {/*<p className="mailchimpForm-subTitle">Never miss a new article!</p>*/}
            </div>
            <form
              className="rightCol"
              id="email-capture"
              method="post"
              noValidate
              onSubmit={this._handleFormSubmit}
            >
              {/*              <label htmlFor="email" className="form-label">
								E-mail:
							</label>*/}

              <input
                type="email"
                name="email"
                id="email"
                placeholder="you@email.com"
                onChange={this._handleEmailChange}
                className="form-input"
              />

              <button type="submit" className="form-submit">
                Subscribe
              </button>

              {this.state.status === `error` && (
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.msg }}
                  className="form-error"
                />
              )}
            </form>
          </div>
        )}
      </div>
    );
  }
}

EmailCaptureForm.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default EmailCaptureForm;

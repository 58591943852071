import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { graphql } from 'gatsby';

import MailchimpForm from '~/components/EmailFormMailchimp/EmailForm.jsx';
import Layout from '~/components/Layout/Layout';

const PostPage = ({ pageContext: { post }, path, data }) => {
  const { social, pages, calendly, title } = data.site.siteMetadata;
  const meta = pages.blog;
  const content = data.markdownRemark.html;

  meta.title = post.title;

  return (
    <Layout
      path={path}
      meta={meta}
      title={title}
      socials={social}
      calendly={calendly}
    >
      <div className="page">
        <h1 className="page-title no-margin">{post.title}</h1>
        <p className="page-date">{post.date}</p>

        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {post.tags && (
          <ul className="post-tags">
            {post.tags.map((tag) => (
              <li key={tag} className="post-tag">
                <Link to={`/tags/${tag}`}>{tag}</Link>
              </li>
            ))}
          </ul>
        )}
        <MailchimpForm />
      </div>
    </Layout>
  );
};

PostPage.propTypes = {
  pageContext: PropTypes.object,
  path: PropTypes.string,
  data: PropTypes.object
};

export default PostPage;

// eslint-disable-next-line one-var
export const pageQuery = graphql`
  query PostPage($path: String!) {
    site {
      siteMetadata {
        title
        calendly {
          kubeflow {
            url
            googleAnalytics {
              category
              action
              label
            }
          }
        }
        contacts {
          department
          email
        }
        pages {
          blog {
            title
            description
            keywords
            googleSiteVerification
          }
        }
        social {
          twitter
          github
          linkedin
          email
          feed
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt
      frontmatter {
        author
        cover {
          childImageSharp {
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        date(formatString: "DD MMMM, YYYY")
        path
        tags
        title
      }
    }
  }
`;
